<script lang="ts" setup>
  import type { PloneTileLinks } from '~/types/Plone'
  import { formatText } from '~/utils/format-text'

  const props = defineProps<{
    data: PloneTileLinks
  }>()

  const columns = [
    props.data.column1_links,
    props.data.column2_links,
    props.data.column3_links,
    props.data.column4_links
  ]

  const columnsAmount = columns.reduce((previous, current) => {
    return current?.length > 0
      ? previous + 1
      : previous
  }, 0)
</script>

<template>
  <app-section
    v-if="columnsAmount > 0"
    class="tile-links"
    :standout="data.display_mode === 'group'"
    :title="data?.title"
    :description="data?.description"
    :read-more-link="data?.more_link?.['@id']"
    :read-more-text="data?.more_link_text || data?.more_link?.title">
    <app-container :narrow="data.display_mode === 'standalone'">
      <app-grid :columns="columnsAmount">
        <template v-for="(links, col) in columns">
          <nav
            v-if="links?.length > 0"
            class="tile-links__column">
            <h3
              v-if="data?.[`column${col+1}_title`]"
              class="u-ff--mono u-fs--300 u-mb--400">
              {{ formatText(data[`column${col+1}_title`]) }}
            </h3>
            <ul class="u-list-unstyled">
              <li
                v-for="link in links"
                class="tile-links__link">
                <template v-if="data.display_mode === 'standalone'">
                  <app-card :href="link.url">
                    {{ formatText(link.label) }}
                    <mdicon
                      v-if="link?.protected"
                      class="u-ml--100 u-c--primary"
                      name="lock"/>
                    <mdicon
                      class="tile-links__arrow"
                      size="40"
                      name="chevron-right"/>
                  </app-card>
                </template>
                <template v-else>
                  <nuxt-link :to="link.url">
                    {{ formatText(link.label) }}
                  </nuxt-link>
                  <mdicon
                    v-if="link?.protected"
                    class="u-ml--100 u-c--primary"
                    name="lock"/>
                </template>
              </li>
            </ul>
          </nav>
        </template>
      </app-grid>
    </app-container>
  </app-section>
</template>

<style scoped>
  .tile-links__column {
    margin-bottom: var(--gap-400);
    @media(--sm) {
      margin-bottom: 0;
    }
  }

  .tile-links__link {
    width: 100%;
    &:deep(.app-card) {
      position: relative;
      width: 100%;
      margin-bottom: var(--gap-300);

      color: var(--c-primary);
      text-decoration: underline;
      font-size: var(--font-size-400);

      &:hover {
        text-decoration: none;
      }
    }
  }

  .tile-links__arrow {
    position: absolute;
    right: var(--gap-200);
    top: 50%;
    transform: translateY(-50%);
  }
</style>
